(function ($) {

    window.onload = function() {
        $('body').append('<iframe src="//гардеробная.онлайн/konfigurator/" style="display: none">');
    };


    /*Плавный скролл*/
    $(".navigate li, .pushy-content li").on("click", "a[href*='/#']", function (event) {
        //отменяем стандартную обработку нажатия по ссылке
        var path = document.location.pathname; //получаем путь страницы на которой находимся
        if(path == '/' || path == '/index.php' ) { //если находимся на главной странице, то выполняем скролл
            event.preventDefault();
            //забираем идентификатор бока с атрибута href
                //узнаем высоту от начала страницы до блока на который ссылается якорь
                var top = $(this.hash).offset().top;
            //анимируем переход на расстояние - top за 1500 мс
            $('body,html').animate({scrollTop: top}, 1000);
        }
    });

    $('a[href="#"]').on('click', function (e) {e.preventDefault();});

    /*Модальник*/

    /*$.fancybox.defaults.hideScrollbar = false;
    $.fancybox.defaults.arrows = false;
    $.fancybox.defaults.toolbar = false;
    $.fancybox.defaults.infobar = false;
    $.fancybox.defaults.touch = false;
    $.fancybox.defaults.wheel = false;*/
    $.fancybox.defaults.closeExisting = true;
    $.fancybox.defaults.hideScrollbar = false;
    $('.ready-works-container').fancybox({
        hideScrollbar : false,
        //arrows: false,
        toolbar: false,
        infobar: false,
        //touch: false,
        //wheel: false,
        closeExisting : true,
        baseTpl:
            '<div class="fancybox-container" role="dialog" tabindex="-1">' +
            '<div class="fancybox-bg"></div>' +
            '<div class="fancybox-inner">' +
            '<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' +
            '<div class="fancybox-toolbar">{{buttons}}</div>' +
            '<div class="fancybox-navigation">{{arrows}}</div>' +
            '<div class="fancybox-stage"></div>' +
            '<div class="fancybox-caption"></div>' +
            "</div>" +
            "</div>",
    });

    $('.popup-content').fancybox();

    document.addEventListener("DOMContentLoaded", function(event) {
        $('.btn-buy')
            .on('click', function (e) {
                e.preventDefault();

                var $option = $(this).parents('.product-option'),
                    id = $option.data('option-id'),
                    fullName =  $(this).parents('form').find('.product-name').text() +' '+$option.data('option-title'),
                    price = +$option.find('.price .value').text(),
                    quantity = +$option.find('.quantity').data('attr-value');

                /*function mathBasket(){
                    var priceBasketFull = 0,
                        quantityProductsFull = 0;

                    $('.basket-tooltip .summ .value').each(function(){
                        priceBasketFull+= +$(this).text();
                    });
                    $('.basket-tooltip .qtty').each(function(){
                        quantityProductsFull+= +$(this).text();
                    });

                    $('.basket-tooltip #jshop_summ_product .value').text(priceBasketFull);
                    $('.basket-info .f_price').text(priceBasketFull);
                    $('.basket-info .product_q').text(quantityProductsFull);
                }
*/

                var data = new FormData($(this).parents('form')[0]);
                $option.find('.option-attr, .quantity').each(function(){
                    var name = $(this).data('attr-name'),
                        value = $(this).data('attr-value');
                    data.append(name, value);
                });
                $.ajax({
                    type: "POST",
                    url: $(this).parents('form').attr('action'),
                    data: data,
                    processData: false,
                    contentType: false,
                    success: function (data) {
                        $.fancybox.open('<div class="bg-callMe text-form">Товар успешно добавлен в корзину</div>', {touch: false});

                        if($('#'+id).length){ //Если товар уже есть в корзине
                            $('#'+id+' .qtty').text(+$('#'+id+' .qtty').text() + quantity); //устанавливаем новое количество
                            $('#'+id+' .summ .value').text(+$('#'+id+' .summ .value').text() + (price*quantity));//устанавливаем новую цену
                        }else{  //если товара нет в корзине
                            $('.basket-tooltip table.module_cart_detail')
                                .append(
                                    '<tr id="'+id+'">' +
                                        '<td class="name">'+fullName+'</td>' +
                                        '<td class="qtty">'+quantity+'</td>' +
                                        '<td class="summ">' +
                                            '<span class="value">'+(quantity*price)+'</span>  р.' +
                                        '</td>' +
                                    '</tr>'
                                );
                        }
                        //mathBasket();//пересчитываем корзину
                        $('#jshop_summ_product .value, .basket-block .basket-info .f_price').text(+$('#jshop_summ_product .value').text() + (quantity*price));
                        $('.basket-block .basket-info .product_q').text(+$('.basket-block .basket-info .product_q').text() + quantity);
                    },
                    error: function (data) {
                        $.fancybox.open('<div class="bg-callMe text-form">Произошла ошибка при добавлении товара.<br><br>Повторите попытку позднее</div>', {touch: false});
                    },
                });
            });

    });
    
    $('.add').on('click',function () {
        var a = +$(this).parent('.quantity').find('.value').text();
        ++a;
        $(this).parent('.quantity').find('.value').text(a);
        $(this).parent('.quantity').data('attr-value',a);
    });
    $('.remove').on('click',function () {
        var a = +$(this).parent('.quantity').find('.value').text();
        if(a>1){
            --a;
            $(this).parent('.quantity').find('.value').text(a);
            $(this).parent('.quantity').data('attr-value',a);
        }

    });
    $('.basket-info').on('click', function(){
        if($(this).parents('.basket-block').find('.basket-tooltip .module_cart_detail tr').length){
            $(this).parents('.basket-block').find('.basket-tooltip').addClass('open');
        }

    });
    $(document).mouseup(function (e){ // событие клика по веб-документу
        var basket = $(".basket-tooltip.open"); // тут указываем ID элемента
        if (!basket.is(e.target) // если клик был не по нашему блоку
            && basket.has(e.target).length === 0) { // и не по его дочерним элементам
            basket.removeClass('open'); // скрываем его
        }
    });
    $("#city").autocomplete({
        source: function(request,response) {
            $.ajax({
                url: "http://api.cdek.ru/city/getListByTerm/jsonp.php?callback=?",
                dataType: "jsonp",
                data: {
                    q: function () { return $("#city").val() },
                    name_startsWith: function () { return $("#city").val() }
                },
                success: function(data) {
                    response($.map(data.geonames, function(item) {
                        return {
                            label: item.name,
                            value: item.name,
                            id: item.id,
                        }
                    }));
                }
            });
        },
        minLength: 1,
        select: function(event,ui) {
            //console.log("Yep!");
            $('#ext_field_1').val(ui.item.id);
        }
    });

    $("#city").on('input', function () {
        $('#ext_field_1').val('');
    });

    $(".jshop.address_block [type=\"submit\"]").on('click', function () {
        if (!$('#ext_field_1').val())
            $("#city").val("");
    });

    $('#main-img').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '#thumb-img'
    });
    $('#thumb-img').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '#main-img',
        //centerMode: true,
        focusOnSelect: true
    });
}(jQuery));